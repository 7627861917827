import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../contexts/AuthContext";
import Card from "../../Card";
import CustomTooltip from "../../CustomTooltip";
import { formatTimestampToLocalTime } from "../../../utils";
import styles from "../ModalProduct.module.sass";

const SIMPLE_CARD_ARTIFACT_ID = "21ec4626-45d7-462e-b2e9-761cd6910b35";
const COMPLEX_CARD_ARTIFACT_ID = "d4d67f9f-6b94-4845-a8cc-b4a523a25827";

const InfoCard = ({ currentCreation }) => {
  const { artifacts } = useAuth();

  const displayName = () => {
    const currentArtifact = artifacts.find(
      (artifact) => artifact.artifact_id === currentCreation?.artifact_id
    );
    if (currentArtifact?.display_name) {
      return currentArtifact.display_name;
    }
    if (currentCreation?.artifact_id === SIMPLE_CARD_ARTIFACT_ID) {
      return "Simple Card";
    }
    if (currentCreation?.artifact_id === COMPLEX_CARD_ARTIFACT_ID) {
      return "Complex Card";
    }
    return "N/A";
  };

  return (
    <>
      <Card>
        <h4>
          <img
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M8 12h8m-8 4h4m4.5 2.5l-1.5-1.5H5A2.5 2.5 0 0 1 2.5 13V5A2.5 2.5 0 0 1 5 2.5h14A2.5 2.5 0 0 1 21.5 5v8A2.5 2.5 0 0 1 19 15.5h-6.5l-3 3Z' /%3E%3C/svg%3E"
            alt="Prompt Icon"
            width={24}
            height={24}
            style={{ marginRight: 8 }}
          />
          <i>{currentCreation?.prompt}</i>
        </h4>
        <p>
          <img
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M16 2H8a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM14 2v4H8V2h6zM8 8h8v12H8V8z' /%3E%3C/svg%3E"
            alt="Type Icon"
            width={24}
            height={24}
            style={{ marginRight: 8 }}
          />
          {displayName()}
        </p>
        <p>
          <img
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M12 14c-3.315 0-6 2.239-6 5v1h12v-1c0-2.761-2.685-5-6-5zM12 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8z' /%3E%3C/svg%3E"
            alt="Creator Icon"
            width={24}
            height={24}
            style={{ marginRight: 8 }}
          />
          {currentCreation?.creator_name}
        </p>
        <p>
          <img
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath d='M3 6h18M3 10h18M7 2v4m10-4v4M5 20h14a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z' /%3E%3C/svg%3E"
            alt="Date Icon"
            width={24}
            height={24}
            style={{ marginRight: 8 }}
          />
          {formatTimestampToLocalTime(
            currentCreation?.timestamp_creation_id?.split("_")[0]
          )}
        </p>
        {currentCreation?.master_color && (
          <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
            <p style={{ fontWeight: "bold", marginRight: 10, marginBottom: 0 }}>
              Master Color
            </p>
            <CustomTooltip
              title={currentCreation?.master_color}
              placement="right"
            >
              <div
                onClick={() => {
                  navigator.clipboard.writeText(currentCreation?.master_color);
                  toast.success("Hex value copied!");
                }}
                style={{
                  backgroundColor: currentCreation?.master_color,
                  borderRadius: "50%",
                  width: 37,
                  height: 37,
                }}
              />
            </CustomTooltip>
          </div>
        )}
      </Card>
      <br></br>
      {currentCreation?.sketch_image_url && (
        <Card>
          <p
            style={{
              fontWeight: "bold",
              marginTop: -25,
              marginBottom: 0,
              padding: 2,
            }}
          >
            {currentCreation.reference_type.charAt(0).toUpperCase() + currentCreation.reference_type.slice(1)}
          </p>
          <img
            src={currentCreation?.sketch_image_url}
            style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            alt="Sketch"
          />
        </Card>
      )}
    </>
  );
};

export default InfoCard;
