import React, { use } from "react";
import cn from "classnames";
import { toast } from "react-hot-toast";
import CustomTooltip from "../../CustomTooltip";
import styles from "../ModalProduct.module.sass";
import { useState } from "react";
import ApiService from "../../../services/ApiService";
import Icon from "../../Icon";
import DownloadService from "../../../services/DownloadService";
import GenerationService from "../../../services/GenerationService";
import TooltipData from "../../CustomTooltip/TooltipsData";
import { Modes } from "../index";
import LoggerService from "../../../services/LoggerService";
import { useEffect } from "react";
import { is } from "date-fns/locale";
import { selectOptions } from "@testing-library/user-event/dist/cjs/utility/selectOptions.js";
import { Height } from "@mui/icons-material";

const VARIATIONS_ICON_SRC = "/images/svg/variations.png";
const REGENERATE_ICON_SRC = "regenerate";
const PAINT_OVER_ICON_SRC = "/images/svg/paint-over-icon.png";
const PAINT_OVER_ICON_DARK_SRC = "/images/svg/paint-over-icon-dark.png";
const MAGIC_CUT_ICON_SRC = "/images/svg/magix.png";
const ERASE_ICON_SRC = "/images/svg/eraser.svg";
const ERASE_ICON_FILLED_SRC = "/images/svg/eraser-filled.svg";
const INPAINTING_ICON_SRC = "/images/svg/edit.svg";
const INPAINTING_ICON_FILLED_SRC = "/images/svg/edit-filled.svg";
const STICKERS_ICON_SRC = "/images/svg/stickers.png";
const STICKERS_ICON_FILLED_SRC = "/images/svg/stickers_filled_icon.svg";
const ENHANCE_ICON_SRC = "/images/svg/genieEnhance.svg";

const Toolbar = ({
  currentCreation,
  availableOperations,
  currentMode,
  setCurrentCreation,
  isGalleryPage,
  onGenerate,
  onClose,
  handlePaintOverMode,
  handleMagicCutMode,
  handleEditMode,
  handleEraseMode,
  handleStickersMode,
  artifactDisplayName,
}) => {
  console.log("isGalleryPage", isGalleryPage);
  console.log("availableOperations", availableOperations);

  const renderActionButton = (
    id,
    title,
    iconSrc,
    onClick,
    isAvailable,
    testId,
    iconSize="30", 
    isActive = false
  ) => { 
    const buttonStyle = isAvailable
    ? {
        backgroundColor: isActive ? "#BCB7B1" : "#BCB7B1", // Highlight color for active state
        border: isActive ? "7px solid #823bf5" : "none", // Optional border for active state
        width: "100px",
        height: "45px",
      }
    : { opacity: 0.3, cursor: "not-allowed"};
    const handleclick = () => {
      if (isAvailable){
        if(!isActive){
          LoggerService.logEvent(
            "info",
            `Button clicked: ${title}`,
            { id: id,
              title: title,
              artifactDisplayName: artifactDisplayName,
              page: "Editor Page",
              action: "Toolbar click",
              feature: title
            });
        }
        onClick();
      }
    }

    return (
    <CustomTooltip id={id} title={title} placement="top">
      <button
        className={cn(styles.buttonGray, !isAvailable && styles.buttonDisabled)}
        onClick={handleclick}
        data-testid={testId}
        disabled={!isAvailable}
        style={buttonStyle}
      >
        {iconSrc.includes("/") ? (
          <Icon src={iconSrc} size={iconSize} />
        ) : (
          <Icon
            name={iconSrc}
            size={iconSize}
            fill={iconSrc == "heart-fill" ? "red" : ""}
          />
        )}
      </button>
    </CustomTooltip>
    );
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleLikeStatus = (creation) => {
    const updatedStatus = !creation.liked;

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creation.creation_id}/${creation.creation_sub_id}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCurrentCreation((prev) => ({ ...prev, liked: updatedStatus }));
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDownload = (type) => {
    setIsDropdownOpen(false);
    const downloadPromise =
      type === "original"
        ? DownloadService.DownloadCreation(currentCreation)
        : DownloadService.DownloadCreationX2(currentCreation);

    toast.promise(downloadPromise, {
      loading: `${type === "original" ? "Downloading" : "Upscaling"} image...`,
      success: "Download successful!",
      error: "Download failed!",
    });
  };

  return (
      <div
        style={{
          position: "absolute",
          top: 4,
          left: "50%",
          transform: "translateX(-51%)",
        }}
      >
        <div style={{ display: "flex" }}>
          <>
            {renderActionButton(
              "Like",
              currentCreation?.liked ? "DisLike" : "Like",
              currentCreation?.liked ? "heart-fill" : "heart",
              () => toggleLikeStatus(currentCreation),
              isGalleryPage ? true : availableOperations?.like,
              "like"
            )}

            <div>
              {renderActionButton(
                "Download",
                TooltipData["Download"],
                "download",
                toggleDropdown,
                isGalleryPage ? true : availableOperations?.download_original,
                "download",
                34
              )}
              {isDropdownOpen && (
                <div
                  style={{
                    position: "absolute",
                    marginTop: 1,
                    zIndex: 10001,
                    display: "flex",
                    flexDirection: "inherit",
                  }}
                >
                  <button
                    className={cn(
                      styles.buttonGray,
                      !isGalleryPage && !availableOperations?.download_original &&
                        styles.buttonDisabled
                    )}
                    onClick={() => handleDownload("original")}
                    data-testid="download"
                    disabled={!isGalleryPage && !availableOperations?.download_original}
                  >
                    Original
                  </button>
                  <button
                    className={cn(
                      styles.buttonGray,
                      !isGalleryPage && !availableOperations?.download_upscale &&
                        styles.buttonDisabled
                    )}
                    onClick={() => handleDownload("x2")}
                    data-testid="downloadx2"
                    disabled={!isGalleryPage && !availableOperations?.download_upscale}
                  >
                    X2
                  </button>
                </div>
              )}
            </div>
            
            {renderActionButton(
              "Enhance",
              TooltipData["Enhance"],
              ENHANCE_ICON_SRC,
              () => {
                GenerationService.GenerateEnhance(
                  currentCreation
                ).then(onGenerate);
                onClose();
              },
              availableOperations?.enhance,
              "Enhance",
              41
            )}
            {!isGalleryPage &&
              currentCreation &&
              (currentCreation.raw_prompt || currentCreation.prompt) && (
                <>
                  {renderActionButton(
                    "Variations",
                    TooltipData["Variations"],
                    VARIATIONS_ICON_SRC,

                    () => {
                      GenerationService.GenerateNewVariations(
                        currentCreation
                      ).then(onGenerate);
                      onClose();
                    },
                    availableOperations?.variations,
                    "variations",
                    45
                  )}
                  {renderActionButton(
                    "Regenerate",
                    TooltipData["Regenerate"],
                    REGENERATE_ICON_SRC,
                    () => {
                      GenerationService.RegenerateCreation(
                        currentCreation
                      ).then(onGenerate);
                      onClose();
                    },
                    availableOperations?.regenerate,
                    "regenerate",
                  )}
                </>
              )}
            {!isGalleryPage &&
              currentCreation &&
              (currentCreation.raw_prompt || currentCreation.prompt) && (
                <>
                  {renderActionButton(
                    "Paint Over",
                    TooltipData["PaintOver"],
                    currentMode == Modes.PAINT_OVER
                      ? PAINT_OVER_ICON_DARK_SRC
                      : PAINT_OVER_ICON_SRC,
                    () => {
                      handlePaintOverMode();
                    },
                    availableOperations?.paint_over,
                    "paint-over",
                    44,
                    currentMode == Modes.PAINT_OVER
                  )}
                  {renderActionButton(
                    "magic-cut",
                    TooltipData["MagicCut"],
                    MAGIC_CUT_ICON_SRC,
                    () => {
                      handleMagicCutMode();
                    },
                    availableOperations?.magic_cut,
                    "magic-cut",
                    34,
                    currentMode == Modes.MAGIC_CUT
                  )}
                  {renderActionButton(
                    "Erase",
                    TooltipData["Erase"],
                    currentMode == Modes.ERASE
                      ? ERASE_ICON_FILLED_SRC
                      : ERASE_ICON_SRC,
                    () => {
                      handleEraseMode();
                    },
                    availableOperations?.erase,
                    "erase",
                    34,
                    currentMode == Modes.ERASE
                  )}
                  {renderActionButton(
                    "Inpainting",
                    TooltipData["Inpainting"],
                    currentMode == Modes.EDIT
                      ? INPAINTING_ICON_FILLED_SRC
                      : INPAINTING_ICON_SRC,
                    () => {
                      handleEditMode();
                    },
                    availableOperations?.inpainting,
                    "Inpainting",
                    32,
                    currentMode == Modes.EDIT
                  )}
                  {renderActionButton(
                    "Stickers",
                    TooltipData["Stickers"],
                    currentMode == Modes.STICKERS
                      ? STICKERS_ICON_FILLED_SRC
                      : STICKERS_ICON_SRC,
                    () => {
                      handleStickersMode();
                    },
                    availableOperations?.stickers,
                    "stickers",
                    28,
                    currentMode == Modes.STICKERS
                  )}
                </>
              )}
          </>
        </div>
      </div>
  );
};

export default Toolbar;
